<template>
  <footer>
    <div>
      <p>For This Project：</p>
      <div class="links">
        <p><a target="_blank" href="https://vuejs.org/">Vuejs</a> (SPA),</p>
        <p><a target="_blank" href="https://symfony.com/">Symfony</a> (REST API),</p>
        <p><a target="_blank" href="https://aws.amazon.com/">AWS EC2 Instance</a> (Deployment),</p>
        <p><a target="_blank" href="http://www.expressjs.com/">Express.js</a> (Deployment Server),</p>
        <p><a target="_blank" href="https://pm2.keymetrics.io/">PM2</a> (Deployment Process Manager)</p>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  text-align: start;
  display: block;
  width: 100%;
  margin-top: 100px;
  background: rgb(38, 50, 56);
  padding: 50px 100px;
  color: white;
  height: max-content;
}

footer > div {
  padding-bottom: 20px;
}

footer > div > p {
  font-size: 16px;
  padding-bottom: 10px;
}

footer > div > div {
  font-size: 12px;
}

a {
  color: #ffc43f;
  text-decoration: none;
}
@media screen and (max-width: 960px) {
  footer{
    padding: 20px 10px;
  }
}
</style>